import React, { useState, useEffect } from 'react';
import { Table, Button, FormControl } from 'react-bootstrap';
import axios from 'axios';
import config from '../../config';

const BASE_URL = config.baseUrl;

const EditPriceList = () => {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [editIndex, setEditIndex] = useState(null);
  const [editPrice, setEditPrice] = useState('');

  useEffect(() => {
    fetchPriceList();
  }, []);

  const fetchPriceList = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/price-list`);
      const sortedProducts = response.data.data.sort((a, b) => a.part_name.localeCompare(b.part_name));
      setProducts(sortedProducts);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching price list:', error);
      setLoading(false);
    }
  };

  const handleEditClick = (index, currentPrice) => {
    setEditIndex(index);
    setEditPrice(currentPrice);
  };

  const handleSaveClick = async (id) => {
    const newPrice = editPrice === '' ? 0 : parseFloat(editPrice);
    try {
      await axios.put(`${BASE_URL}/price-list/update`, { id, price: newPrice });
      setProducts(products.map((product) => (product.id === id ? { ...product, price: newPrice } : product)));
      setEditIndex(null);
    } catch (error) {
      console.error('Error updating price:', error);
    }
  };

  return (
    <div className="container mt-4">
      <h2>Edit Price List</h2>
      {loading ? (
        <p>Loading price list...</p>
      ) : (
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>Name</th>
              <th>Part No.</th>
              <th>Type</th>
              <th>Price</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {products.map((product, index) => (
              <tr key={product.id}>
                <td>{product.part_name}</td>
                <td>{product.part_no}</td>
                <td>{product.part_type}</td>
                <td>
                  {editIndex === index ? (
                    <FormControl type="number" value={editPrice} onChange={(e) => setEditPrice(e.target.value)} />
                  ) : (
                    product.price.toFixed(2)
                  )}
                </td>
                <td>
                  {editIndex === index ? (
                    <Button variant="success" onClick={() => handleSaveClick(product.id)}>Save</Button>
                  ) : (
                    <Button variant="warning" onClick={() => handleEditClick(index, product.price)}>Edit</Button>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}
    </div>
  );
};

export default EditPriceList;
