import React, { useEffect, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { getAllBrands } from '../../api'; // Adjust the import path if necessary

function Section3() {
  const [brands, setBrands] = useState([]);

  useEffect(() => {
    // Fetch brands from the API when the component mounts
    const fetchBrands = async () => {
      try {
        const brandData = await getAllBrands();
        setBrands(brandData.data);


      } catch (error) {
        console.error('Failed to fetch brands:', error);
      }
    };

    fetchBrands();
  }, []);

  return (
    <Row className="section3 mb-4">
      <Col>
        <h4 className="section2-title">Featured Brands</h4>
        <div className="brand-logos-wrapper">
          <div className="brand-logos d-flex justify-content-between">
            <img src="https://i.postimg.cc/SR0pQrCW/samsung.png" alt="Brand 1" className="brand-logo" />
            <img src="https://i.postimg.cc/sDhHh7d6/haier.png" alt="Brand 2" className="brand-logo" />
            <img src="https://i.postimg.cc/L8kyySsj/sony.png" alt="Brand 3" className="brand-logo" />
            <img src="https://i.postimg.cc/mrbmJncJ/tcl.png" alt="Brand 4" className="brand-logo" />
            <img src="https://i.postimg.cc/1XNBDhTn/vu.png" alt="Brand 5" className="brand-logo" />
            <img src="https://i.postimg.cc/nMbkkSbd/mi.png" alt="Brand 6" className="brand-logo" />
          </div>
        </div>
      </Col>
    </Row>

  );
}

export default Section3;
