
import React, { useState } from 'react';
import { Row, Col, Carousel, Modal, Button } from 'react-bootstrap';
import { Link,useNavigate } from 'react-router-dom';
import { useGlobalState } from '../../contexts/GlobalStateContext';
import Badge from 'react-bootstrap/Badge'

function Section1() {
  const { isLoggedIn } = useGlobalState(); // Check if the user is logged in
  const [showModal, setShowModal] = useState(false); // State to control the modal
  const [showSecondImageModal, setShowSecondImageModal] = useState(false);
  const navigate = useNavigate();

  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);

  const handleShowSecondImageModal = () => setShowSecondImageModal(true);
  const handleCloseSecondImageModal = () => setShowSecondImageModal(false);

  const handlePriceListClick = (e) => {
    if (!isLoggedIn) {
      e.preventDefault(); // Prevent navigation
      handleShowModal(); // Show the modal
    }
  };

  const handleSecondImageClick = (e) => {
    if (!isLoggedIn) {
      e.preventDefault(); // Prevent navigation
      handleShowSecondImageModal(); // Show the modal
    } else {
      navigate('/pricing'); // Navigate to the desired route if logged in
    }
  };

  return (
    <>
      <Row className="section1">
        <Col md={4} className="card">
          <Row className="services">
            <ul className="services-list">
              <li><h2>SERVICES</h2></li>
              <li>
                <Link
                  to="/price-list"
                  style={{ textDecoration: 'none', color: 'inherit' }}
                  onClick={handlePriceListClick} // Handle click event
                >
                Price List
                </Link>
                
              </li>
              <li>Digital Solutions
              <Badge className="badges" pill bg="warning">
                  Coming Soon
                </Badge>
              </li>
              <li>Electronic Parts
              
              </li>
              <li>Electronic Product Services
              <Badge className="badges" pill bg="warning">
                  Coming Soon
                </Badge>
              </li>
            </ul>
          </Row>
        </Col>
        <Col md={8} className="image-carousel">
         
          <Carousel interval={3000}>
            <Carousel.Item>
              <img
                className="d-block w-100"
                src="https://i.postimg.cc/ZRdmWc8D/IMG-20250317-WA0002-1.jpg"
                alt="First slide"
              />
              <Carousel.Caption>
          
              </Carousel.Caption>
            </Carousel.Item>

            <Carousel.Item onClick={handleSecondImageClick}>
              <img
                className="d-block w-100"
                src="https://i.postimg.cc/FsqxZV3x/helptechi-main-1-1.png"
                alt="Second slide"
              />
              <Carousel.Caption>
              
              </Carousel.Caption>
            </Carousel.Item>

            <Carousel.Item>
              <img
                className="d-block w-100"
                src="https://i.postimg.cc/d03hM92J/satguru-optimized.png"
                alt="Third slide"
              />
              <Carousel.Caption>
               
              </Carousel.Caption>
            </Carousel.Item>
          </Carousel>
        </Col>
      </Row>

      {/* Modal for login prompt */}
      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Login Required</Modal.Title>
        </Modal.Header>
        <Modal.Body>Please login to view the price list.</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showSecondImageModal} onHide={handleCloseSecondImageModal}>
        <Modal.Header closeButton>
          <Modal.Title>Login Required</Modal.Title>
        </Modal.Header>
        <Modal.Body>Please login to View/Subscribe the Price List</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseSecondImageModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      
    </>
  );
}

export default Section1;
