import React from "react";
import styles from './SearchBar.module.css'
import { IoSearch } from "react-icons/io5";
import { useNavigate } from 'react-router-dom';


function SearchBar() {
    const [searchQuery, setSearchQuery] = React.useState('');
    const navigate = useNavigate();

    function handleSearchQuery(e){
        setSearchQuery(e.target.value)
    }

    function handleSubmit(e){
        e.preventDefault();
        navigate(`/all-products?search=${encodeURIComponent(searchQuery)}`);
    }
    
    return (
        <section className={styles['search__main']}>
            <form onSubmit={handleSubmit} className={styles.form}>
                <div className={styles['form__input']}>
                    <input type="text" onChange={handleSearchQuery} placeholder="Type here..."/>
                </div>
                <div>
                    <button class="btn btn-outline-success">Search</button>
                </div>
            </form>
        </section>
    )
}

export default SearchBar;