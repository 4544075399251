import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import { FaTimesCircle } from 'react-icons/fa'; // For the red cross symbol
import './Failed.css'; // Include this CSS file for styling

const Failed = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const timer = setTimeout(() => {
      navigate('/'); // Redirect to home after 3 seconds
    }, 3000);

    return () => clearTimeout(timer); // Clear timeout if the component unmounts
  }, [navigate]);

  return (
    <Modal show centered>
      <Modal.Body className="text-center">
        <FaTimesCircle className="status-icon failed" />
        <h4 className="mt-3">Payment Failed</h4>
        <p>Unfortunately, your payment could not be processed.</p>
      </Modal.Body>
    </Modal>
  );
};

export default Failed;
