
import React, { useEffect, useState } from 'react';
import { Card, Button, Container, Row, Col, Modal } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useGlobalState } from '../../contexts/GlobalStateContext';
import { fetchPlans, initiateSubscription } from '../../api';
import axios from 'axios';
const PricingPage = () => {
  const { isLoggedIn } = useGlobalState(); // Access login state from context
  const [showModal, setShowModal] = useState(false);
  const [plans, setPlans] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  // Fetch subscription plans on component mount
  useEffect(() => {
    const loadPlans = async () => {
      try {
        const data = await fetchPlans();
        
        setPlans(data.plans);
      } catch (err) {
        console.error('Failed to load subscription plans:', err);
      } finally {
        setLoading(false);
      }
    };

    loadPlans();
  }, []);


  const handleSubscribe = async (planType,planId) => {
    if (!isLoggedIn) {
        setShowModal(true);
    } else {
        try {
          const userId = JSON.parse(localStorage.getItem('userDetails')); // Assuming userId is stored
          const userMobile = localStorage.getItem('userMobile');
          const response = await initiateSubscription(userId.id,userMobile ,planType, planId);
            
            if (response.msg === 'OK') {
                window.location.href = response.url; // Redirect to PhonePe checkout
            } else {
                alert('Payment initiation failed. Please try again.');
            }
        } catch (error) {
            console.error('Error initiating payment:', error);
            alert('An error occurred while processing your payment.');
        }
    }
};


  return (
    <Container className="mt-5">
      <h1 className="text-center mb-4">Choose Your Plan</h1>
      {loading ? (
        <p>Loading plans...</p>
      ) : (
        <Row>
          {plans.map((plan) => (
            <Col md={4} key={plan.type} className="mb-4">
              <Card className="text-center">
                <Card.Body>
                  <Card.Title>{plan.type} Plan</Card.Title>
                  <Card.Text>
                    <h4>₹{plan.price}</h4>
                    <p>Duration: {plan.duration_in_days} Days</p>
                  </Card.Text>
                  <Button variant="primary" onClick={() => handleSubscribe(plan.type,plan.id)}>
                    Subscribe
                  </Button>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      )}

      {/* Modal to ask user to login */}
      <Modal show={showModal} onHide={() => setShowModal(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>Login Required</Modal.Title>
        </Modal.Header>
        <Modal.Body>You need to log in to subscribe to a plan.</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Close
          </Button>
          
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export default PricingPage;
