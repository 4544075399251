import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import { FaCheckCircle } from 'react-icons/fa'; // For the green check symbol
import './Success.css'; // Include this CSS file for styling

const Success = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const timer = setTimeout(() => {
      navigate('/'); // Redirect to home after 3 seconds
    }, 3000);

    return () => clearTimeout(timer); // Clear timeout if the component unmounts
  }, [navigate]);

  return (
    <Modal show centered>
      <Modal.Body className="text-center">
        <FaCheckCircle className="status-icon success" />
        <h4 className="mt-3">Payment Successful!</h4>
        <p>Your payment was processed successfully.</p>
      </Modal.Body>
    </Modal>
  );
};

export default Success;
