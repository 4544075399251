import React from 'react';
import styles from './Nav.module.css'

function Hamburger(){
   return (
    <div className={styles.hamburger__wrapper}>
        <span className={styles.hamburger__ham}></span>
        <span className={styles.hamburger__ham}></span>
        <span className={styles.hamburger__ham}></span>
    </div>
   ) 
}

export default Hamburger;