import React, { useState, useEffect } from 'react';
import { Container, Table, Button, Alert, Modal } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS
import { getPriceList, subStatus } from '../../api';

const Subscription = () => {
  const [showModal, setShowModal] = useState(false);
  const [selectedSubscriptionId, setSelectedSubscriptionId] = useState(null);
  const [subscriptionStatus, setSubscriptionStatus] = useState(null);
  const [subscriptionData, setSubscriptionData] = useState(null);  // Added to hold fetched data

  const userMobile = localStorage.getItem('userMobile');

  // Fetch subscription status
  useEffect(() => {
    const fetchSubscriptionStatus = async () => {
      if (userMobile) {
        try {
          const response = await subStatus(userMobile);
          console.log(response);
          setSubscriptionStatus(response.subscriptionStatus); // Update the subscription status state
          setSubscriptionData(response);  // Store fetched subscription data
        } catch (error) {
          console.error('Error fetching subscription status:', error);
        }
      }
    };

    fetchSubscriptionStatus();
  }, [userMobile]);

  // Handle cancel button click
  const handleCancelClick = () => {
    setShowModal(true);
  };

  // Handle confirm cancel
  const handleConfirmCancel = () => {
    if (subscriptionData) {
      console.log(`Cancel subscription for mobile: ${userMobile}`);
      alert(`Subscription with ID ${subscriptionData.userId} has been canceled.`);
      // Reset state and close modal
      setShowModal(false);
    }
  };

  // Handle close modal
  const handleCloseModal = () => {
    setShowModal(false);
  };

  // Check if there is a subscription data
  const hasSubscription = subscriptionData !== null;

  return (
    <Container className="py-5">
      <h2 className="text-center mb-4">Your Subscription</h2>
      {hasSubscription ? (
        <Table striped bordered hover responsive>
          <thead>
            <tr>
              <th>Subscription Type</th>
              <th>Start Date</th>
              <th>End Date</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{subscriptionData.subscriptionType}</td>
              <td>{new Date(subscriptionData.subscriptionStartDate).toLocaleDateString()}</td>
              <td>{new Date(subscriptionData.subscriptionEndDate).toLocaleDateString()}</td>
              <td>{subscriptionStatus || 'Not Active'}</td>
            </tr>
          </tbody>
        </Table>
      ) : (
        <Alert variant="info">
          You have no active subscription at the moment.
        </Alert>
      )}

      {/* Confirmation Modal */}
      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Cancellation</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to cancel this subscription? This action cannot be undone.
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
          <Button variant="danger" onClick={handleConfirmCancel}>
            Cancel Subscription
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export default Subscription;
