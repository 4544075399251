import React, { useState, useEffect } from 'react';
import { Table, Container, Modal, Button } from 'react-bootstrap';
import { useGlobalState } from '../../contexts/GlobalStateContext';
import axios from 'axios'; // Import axios for API calls
import 'bootstrap/dist/css/bootstrap.min.css';
import { getPriceList, subStatus } from '../../api';
import styles from './PriceList.module.css'
import { useLoader } from '../../contexts/LoaderContext';

const PriceList = () => {
  const { isLoggedIn } = useGlobalState(); // Get the isLoggedIn state from context
  const { showLoader, hideLoader } = useLoader();
  const [showModal, setShowModal] = useState(false);
  const [showSubscriptionModal, setShowSubscriptionModal] = useState(false); // State for subscription modal
  const [products, setProducts] = useState([]); // State to hold the price list data
  const [loading, setLoading] = useState(true); // Loading state
  const [subscriptionStatus, setSubscriptionStatus] = useState(null); // State to hold the subscription status

  const userMobile = localStorage.getItem('userMobile');

  // Fetch the subscription status when the component mounts
  useEffect(() => {
    const fetchSubscriptionStatus = async () => {
      if (userMobile) {
        try {
          const response = await subStatus(userMobile);
          setSubscriptionStatus(response.subscriptionStatus); // Update the subscription status state
        } catch (error) {
          console.error('Error fetching subscription status:', error);
        }
      }
    };

    fetchSubscriptionStatus();
  }, [userMobile]);

  // Fetch the price list when logged in and the subscription is active
  // useEffect(() => {
  //   if (isLoggedIn && subscriptionStatus === 'active') {
  //     const fetchPriceList = async () => {
  //       showLoader();
  //       try {
  //         const fetchList = await getPriceList();
  //         setProducts(fetchList.data); // Set the price list data from the response
  //         setLoading(false); // Set loading to false when data is fetched
  //       } catch (error) {
  //         console.error('Error fetching price list', error);
  //         setLoading(false);
  //       } finally {
  //         hideLoader(); // Hide the loader after fetching data
  //       }
  //     };

  //     fetchPriceList();
  //   }
  // }, [isLoggedIn, subscriptionStatus, showLoader, hideLoader]);

  useEffect(() => {
    if (isLoggedIn && subscriptionStatus === 'active') {
      const fetchPriceList = async () => {
        showLoader();
        try {
          const fetchList = await getPriceList();
          const sortedList = fetchList.data.sort((a, b) => a.part_name.localeCompare(b.part_name)); // Sorting by part_name
          setProducts(sortedList);
          setLoading(false);
        } catch (error) {
          console.error('Error fetching price list', error);
          setLoading(false);
        } finally {
          hideLoader();
        }
      };
  
      fetchPriceList();
    }
  }, [isLoggedIn, subscriptionStatus, showLoader, hideLoader]);
  

  const handleOpenModal = () => {
    if (!isLoggedIn) {
      setShowModal(true); // Show modal if not logged in
    } else if (subscriptionStatus !== 'active') {
      setShowSubscriptionModal(true); // Show subscription modal if subscriptionStatus is not active
    }
  };

  const currentDate = new Date().toLocaleDateString('en-GB');

  return (
    <Container className="my-4">
      <h2 className="mb-4">Price List ({currentDate})</h2>
      {!isLoggedIn || subscriptionStatus !== 'active' ? (
        <Button variant="primary" onClick={handleOpenModal}>
          View Price List
        </Button>
      ) : loading ? (
        <p>Loading price list...</p>
      ) : (
        <Table responsive striped bordered hover>
          <thead>
            <tr>
              <th >Name</th>
              <th>Part No.</th>
              <th >Type</th>
              <th >Price</th>
             
            </tr>
          </thead>
          <tbody className={styles.tbody}>
            {products.map((product, index) => (
              <tr key={index}>
                <td className="small-font">{product.part_name}</td>
                <td className="small-font">{product.part_no}</td>
                <td className="small-font">{product.part_type}</td>
                <td className="small-font">{product.price.toFixed(2)}</td>
                
              </tr>
            ))}
          </tbody>
        </Table>
      )}

      {/* Modal to ask user to login */}
      <Modal show={showModal} onHide={() => setShowModal(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>Login Required</Modal.Title>
        </Modal.Header>
        <Modal.Body>Please login to view the price list.</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Modal to ask user to subscribe */}
      <Modal show={showSubscriptionModal} onHide={() => setShowSubscriptionModal(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>Subscription Required</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Your subscription is either inactive or missing. Please subscribe to access the price list.
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowSubscriptionModal(false)}>
            Close
          </Button>
          {/* You can add a button to redirect to the subscription page */}
          <Button variant="primary" href="/pricing">
            Subscribe Now
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export default PriceList;


