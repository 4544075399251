import React, { useState, useEffect } from 'react';
import { Container, Card, Spinner, Alert, Button } from 'react-bootstrap';
import { getAllProducts } from '../../api'; // Ensure this import path is correct
import { useNavigate } from 'react-router-dom'; // Ensure this import is correct
import './Section2.css';
const FEATURED_SKUS = ["6518110001", "6518110002", "6518110003", "6518110004"];

const Section2 = () => {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate(); // Initialize navigate

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await getAllProducts();
        if (response.status === 200 && Array.isArray(response.data)) {
          const featuredProducts = response.data.filter(product => FEATURED_SKUS.includes(product.product_sku));
          setProducts(featuredProducts);
        } else {
          throw new Error('Invalid data format');
        }
      } catch (error) {
        setError('Failed to load products.');
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    fetchProducts();
  }, []);

  const handleViewProduct = (productName) => {
    navigate(`/${productName}`); // Updated path to match new route structure
  };

  if (loading) {
    return (
      <Container className="">
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </Container>
    );
  }

  if (error) {
    return (
      <Container className="">
        <Alert variant="danger">{error}</Alert>
      </Container>
    );
  }

  return (
    <Container className="">
      <h2 className="section2-title">Featured Products</h2>
      {products.length > 0 ? (
        <div className="product-grid">
          {products.map((product) => {
            const topSeller = product.sellers.reduce((prev, current) => (prev.seller_id < current.seller_id ? prev : current), product.sellers[0]);
            return (
              <div className="" key={product.product_sku}>
                <Card className="featured-product-card">
                  <div className="product-image-container" onClick={() => handleViewProduct(product.product_sku)}>
                    <img
                      src={product.main_image || 'https://via.placeholder.com/150'}
                      alt={product.product_sku || 'Product Image'}
                      className="product-image"
                    />
                  </div>
                  <Card.Body>
                    <Card.Title className="product-title title">
                      {product.product_name || 'Product Name'}
                    </Card.Title>
                    <div className="seller-info">
                      <p className="seller-name">{topSeller.seller_name || 'Unknown Seller'}</p>
                      <p className="seller-city">{topSeller.city_name || 'Unknown City'}</p>
                    </div>
                    <div className="button-group">
                      <Button variant="primary" onClick={() => handleViewProduct(product.product_sku)}>
                        View Product
                      </Button>
                    </div>
                  </Card.Body>
                </Card>
              </div>
            );
          })}
        </div>
      ) : (
        <p>No featured products available</p>
      )}
    </Container>
  );
};

export default Section2;
