import React from 'react';
import Logo from '../Logo/Logo'
import SearchBar from '../SearchBar/SearchBar';
import Hamburger from './Hamburger';
import { useNavigate } from 'react-router-dom';
import { Navbar, Nav as NavComponent, Form, FormControl, Button, NavDropdown, Container, Modal, Dropdown } from 'react-bootstrap';
import { getAllCities } from '../../api'; // Assuming you have this API call
import LoginRegister from '../LoginRegister/LoginRegister';
import { useGlobalState } from '../../contexts/GlobalStateContext';
import styles from './Nav.module.css'
import { RxCross1 } from "react-icons/rx";
import { FaHome, FaUser, FaUsers } from "react-icons/fa";
import { TbLogin2 } from "react-icons/tb";

const Nav = () => {
  const [cities, setCities] = React.useState([]);
  const [showModal, setShowModal] = React.useState(false);
  const [isHamburgerOpen, setIsHamburgerOpen] = React.useState(false);
  const { selectedCity, setSelectedCity, user, logout } = useGlobalState();
  const navigate = useNavigate();

  React.useEffect(() => {
    const fetchCities = async () => {
      try {
        const data = await getAllCities();
        setCities(data.data);
      } catch (error) {
        console.error('Error fetching cities:', error);
      }
    };

    fetchCities();
  }, []);

  const handleCitySelect = (city) => {
    setSelectedCity(city);
  };

  const handleLoginSuccess = () => {
    setShowModal(false);
  };

  const handleNavigation = (route) => {
    setIsHamburgerOpen(false);
    navigate(route);
  };

  const handleSubscriptionClick = () => {
    const userDetails = JSON.parse(localStorage.getItem('userDetails'));
    const subscriptionStatus = userDetails?.subscription_status || 'inactive';

    if (subscriptionStatus === 'active') {
      setIsHamburgerOpen(false);
      navigate('/price-list');
    } else {
      setIsHamburgerOpen(false);
      navigate('/pricing');
    }
  };

  return (
    <>
      <section className={styles.maxWidth}>
        <nav className={styles.nav}>
          <section className={styles.logo}>
            <button className={styles.hamburger} onClick={() => setIsHamburgerOpen(true)}>
              <Hamburger />
            </button>
            <Logo />
          </section>
          <section className={styles.nav__signin}>
            {user ? null : (
              <button onClick={() => setShowModal(true)}>Log In
                <div className={styles.nav__signin_login}><TbLogin2 /></div>
              </button>
            )}
          </section>
          <section className={styles.nav__searchbar}>
            <SearchBar />
          </section>
          <section className={styles.nav__right}>
            <div>
              <Navbar sticky="top">
                <Container>
                  <NavComponent className="ml-auto">
                    <NavDropdown title="Subscriptions" id="pricingDropdown">
                      <NavDropdown.Item onClick={handleSubscriptionClick}>
                        Price List
                      </NavDropdown.Item>
                    </NavDropdown>

                    <NavComponent.Link href="/about-us">About Us</NavComponent.Link>
                    <NavDropdown title={selectedCity ? selectedCity.name : "Select City"} id="navbarScrollingDropdown">
                      {cities.map((city) => (
                        <NavDropdown.Item
                          key={city.id}
                          onClick={() => handleCitySelect(city)}
                        >
                          {city.name}
                        </NavDropdown.Item>
                      ))}
                    </NavDropdown>

                    {user ? (
                      <Dropdown>
                        <Dropdown.Toggle variant="outline-secondary" id="dropdown-basic">
                          My Account<i className="fas fa-user-circle"></i>
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          <Dropdown.Item href="/my-profile">View Account</Dropdown.Item>
                          <Dropdown.Item onClick={() => {
                            logout();
                            navigate('/');
                          }}>Log Out</Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    ) : (
                      <Button className="LoginButton" onClick={() => setShowModal(true)}>Log In</Button>
                    )}
                  </NavComponent>
                </Container>
              </Navbar>
            </div>
          </section>
        </nav>
      </section>

      {isHamburgerOpen &&
        <section className={styles.mobileNav}>
          <div className={styles.mobileNav__nav}>
            <section className={styles['mobileNav__nav--header']}>
              <a href="/">
                <p className={styles.mobileNav__secName}>Browse</p>
                <p>HelpTechi</p>
              </a>
            </section>
            <section className={styles['mobileNav__nav--content']}>
              <h6>
                <a onClick={() => handleNavigation('/')} className={styles.apart}> HelpTechi Home <FaHome /> </a>
              </h6>
            </section>
            <hr style={{ margin: '.5rem 0' }} />
            <section className={styles['mobileNav__nav--content']}>
              <h6>Subscriptions</h6>
              <div>
                <ul>
                  <li onClick={handleSubscriptionClick}>Price List</li>
                </ul>
              </div>
            </section>
            <hr style={{ margin: '.5rem 0' }} />

            <section className={styles['mobileNav__nav--content']}>
              <h6 className={styles.apart}>User Accounts<FaUser /> </h6>
              <div>
                <ul>
                  {user ? (
                    <>
                      <li> <button onClick={() => handleNavigation('/my-profile')}>View Account</button></li>
                      <li> <button onClick={() => { logout(); handleNavigation('/') }}>Log Out</button></li>
                    </>
                  ) : (
                    <li> <button onClick={() => { setIsHamburgerOpen(false); setShowModal(true) }}>Log In</button></li>
                  )}
                </ul>
              </div>
            </section>

            <hr style={{ margin: '.5rem 0' }} />

            <section className={styles['mobileNav__nav--content']}>
              <h6>
                <a onClick={() => handleNavigation('about-us')} className={styles.apart}> About Us <FaUsers /> </a>
              </h6>
            </section>

            <hr style={{ margin: '.5rem 0' }} />
          </div>
          <div className={styles['mobileNav__nav--cancel']}>
            <button onClick={() => { setIsHamburgerOpen(false) }}>
              <RxCross1 />
            </button>
          </div>
        </section>
      }

      <Modal
        show={showModal}
        onHide={() => setShowModal(false)}
        centered
        dialogClassName="custom-modal"
      >
        <Modal.Body className="modal-body">
          <LoginRegister closeModal={() => setShowModal(false)} onLoginSuccess={handleLoginSuccess} />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Nav;
