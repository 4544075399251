import React, { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { getProductById } from '../../api';
import { Container, Row, Col } from 'react-bootstrap';
import SellerList from './SellerList';
import './ProductPage.css';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa'; // Import FontAwesome icons

const ProductPage = () => {
  const { product_sku } = useParams();
  const [product, setProduct] = useState(null);
  const [currentImage, setCurrentImage] = useState('');
  const [currentImageIndex, setCurrentImageIndex] = useState(0);  // Track the index of the current image
  const [showSellerList, setShowSellerList] = useState(false);
  const [featuredSeller, setFeaturedSeller] = useState(null);
  const altImageContainerRef = useRef(null);

  useEffect(() => {
    const fetchProduct = async () => {
      try {
        const fetchedProduct = await getProductById(product_sku);
        setProduct(fetchedProduct);
        setCurrentImage(fetchedProduct.main_image);
        setCurrentImageIndex(0);  // Set initial index
        setFeaturedSeller(fetchedProduct.sellers.reduce((min, curr) =>
          curr.seller_id < min.seller_id ? curr : min, fetchedProduct.sellers[0]));
      } catch (error) {
        console.error('Error fetching product:', error);
      }
    };

    fetchProduct();
  }, [product_sku]);

  if (!product) {
    return <div>Loading...</div>;
  }

  const handleImageClick = (image, index) => {
    setCurrentImage(image);
    setCurrentImageIndex(index);  // Update the index when a specific image is clicked
  };

  const scrollAltImages = (direction) => {
    const images = [product.main_image, ...product.alt_images];
    let newIndex = currentImageIndex;

    if (direction === 'left') {
      newIndex = currentImageIndex === 0 ? images.length - 1 : currentImageIndex - 1;  // Loop to the last image
    } else {
      newIndex = currentImageIndex === images.length - 1 ? 0 : currentImageIndex + 1;  // Loop to the first image
    }

    setCurrentImage(images[newIndex]);
    setCurrentImageIndex(newIndex);  // Update the index
  };

  const handleShowSellerList = () => setShowSellerList(true);
  const handleCloseSellerList = () => setShowSellerList(false);

  const handleSelectSeller = (selectedSeller) => {
    setFeaturedSeller(selectedSeller);
    handleCloseSellerList();
  };

  const sellersToShow = product.sellers.filter(seller => seller.seller_id !== featuredSeller.seller_id);

  return (
    <>
      <Container className="product-page">
        <Row className="product-page-top">
          <Col className="product-images">
            <img src={currentImage} alt={product.product_sku} className="main-image" />
            <div className="alt-images-container">
              <button className="scroll-button left" onClick={() => scrollAltImages('left')}>
                <FaChevronLeft size={24} /> {/* Replace with a good icon */}
              </button>
              <div className="alt-images" ref={altImageContainerRef}>
                {[product.main_image, ...product.alt_images].map((image, index) => (
                  <img 
                    key={index} 
                    src={image} 
                    alt={`${product.product_sku} alt ${index + 1}`} 
                    className={`alt-image ${currentImageIndex === index ? 'selected' : ''}`}  // Highlight selected image
                    onClick={() => handleImageClick(image, index)}  // Pass the index when clicking an image
                  />
                ))}
              </div>
              <button className="scroll-button right" onClick={() => scrollAltImages('right')}>
                <FaChevronRight size={24} /> {/* Replace with a good icon */}
              </button>
            </div>
          </Col>

          <Col className="product-info">
            <h1 className="product-name">{product.product_name}</h1>
            <div className="specifications">
              <h2 className="section-title">Specification:</h2>
              <table className="specifications-table">
                <tbody>
                  {Object.entries(product.specification).map(([key, value]) => (
                    <tr key={key}>
                      <td className="specification-key"><strong>{key}:</strong></td>
                      <td className="specification-value">{value}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className="key-features">
              <h2 className="section-title">Key Features:</h2>
              <ul>
                {product.key_features.map((feature, index) => (
                  <li key={index}>{feature}</li>
                ))}
              </ul>
            </div>
          </Col>

          <Col className="product-seller">
            <div className="seller-title">Seller Information</div>
            <div className="seller-details">
              <div className="seller-detail-item">
                <strong>Sold By:</strong> {featuredSeller.seller_name}
              </div>
              <div className="seller-detail-item">
                <strong>Area:</strong> {featuredSeller.city_name}
              </div>
            </div>
            <div className="seller-btn">
              <button className="contact-seller-btn">Contact Seller</button>
              <button className="see-other-sellers-btn" onClick={handleShowSellerList}>See Other Sellers</button>
            </div>
          </Col>
        </Row>

        <Row className="product-description">
          <div className="description-title">Description</div>
          <p>{product.description}</p>
        </Row>
      </Container>

      <SellerList 
        show={showSellerList} 
        onHide={handleCloseSellerList} 
        sellers={sellersToShow} 
        onSelectSeller={handleSelectSeller}
      />
    </>
  );
};

export default ProductPage;
