

import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Table, Container, Spinner } from 'react-bootstrap';
import config from '../../config';

const BASE_URL = config.baseUrl;

const UserList = () => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);


  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const mobile = localStorage.getItem('userMobile');
        const response = await axios.post(`${BASE_URL}/users/list`, { mobile });
        console.log('User list:', response.data.users);
        
        // Sorting users based on first_name
        const sortedUsers = response.data.users.sort((a, b) => a.first_name.localeCompare(b.first_name));
        
        setUsers(sortedUsers);
      } catch (error) {
        console.error('Error fetching users:', error);
      } finally {
        setLoading(false);
      }
    };
  
    fetchUsers();
  }, []);
  return (
    <Container className="mt-4">
      <h3 className="mb-3 text-center">User List</h3>

      {loading ? (
        <div className="text-center">
          <Spinner animation="border" variant="primary" />
          <p>Loading users...</p>
        </div>
      ) : (
        <Table striped bordered hover responsive className="shadow-sm">
          <thead className="table-dark">
            <tr>
              <th>#</th>
              <th>First Name</th>
              <th>Last Name</th>
              <th>Shop Name</th>
              <th>Mobile</th>
              <th>Address</th>
              <th>Subscription Status</th>
            </tr>
          </thead>
          <tbody>
            {users.length > 0 ? (
              users.map((user, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{user.first_name}</td>
                  <td>{user.last_name}</td>
                  <td>{user.shop_name}</td>
                  <td>{user.mobile}</td>
                  <td>{user.address}</td>
                  <td>{user.subscription_status}</td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="5" className="text-center">
                  No users found
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      )}
    </Container>
  );
};

export default UserList;
