// import React from 'react';
// import './Footer.css';

// const Footer = () => {
//   return (
//     <div className="Footer-Total">
//       <div className="footer">
//       <div className="footer-upper">
//         <div className="footer-section logo-section">
//           <img
//     src="https://i.ibb.co/dM00xCs/icon.png"
//     alt="HelpTechi Logo"
//     style={{ height: '150px' }}
//   />
//         </div>
//         <div className="footer-section company-section">
//           <h4>HelpTechi</h4>
//           <div className="footer-links">
//             <a href="/">Home</a>
//             <div>
//             <a href ="/about-us">About Us</a>
//             </div>
//             <div>
//             <a href ="/contact">Contact Us</a>
//             </div>
//             {/* <div>Queries & Solutions</div> */}
          
//           </div>
//         </div>
//         <div className="footer-section help-section">
//           <h4>Support</h4>
//           <div className="help-item">
//             <span className="material-symbols-outlined location-icon">
//               location_on
//             </span>
//             <span>Prime Solution Hub,<br></br>
//               Shop No.1, Akshey Apartment Rambaug, Lane No.5, Near Vani Vidaylya Kalyan, Thane(421301)</span>
//           </div>
//           <br></br>
//           <div className="help-item">
//             <span className="material-symbols-outlined location-icon">
//               mail
//             </span>
//             inquiries.solutions@helptechi.com
//           </div>
//           <div className="help-item">
//             <span className="material-symbols-outlined location-icon">
//               call
//             </span>
//             9467-586-586
//           </div>
//         </div>
//         {/* <div className="footer-section questions-section">
//           <h4>Recent Questions</h4>
//           <div className="footer-links">
//             <div>What is HelpTech? <span className="label free">Free</span></div>
//             <div>How to clean an LED TV? <span className="label free">Free</span></div>
//             <div>How to factory reset Android TV in a few seconds? <span className="label paid">Paid</span></div>
//           </div>
//         </div> */}
//       </div>
//      <div>
//      <div className="footer-lower">
//         <p>
//           &copy;2024 HelpTechi | All rights reserved | <a href="/terms-of-use">Terms & Conditions</a> | <a href="/privacy-policy">Privacy Policy</a>
//         </p>
//         <div className="social-media">
//           <a href="https://www.instagram.com" target="_blank" rel="noopener noreferrer">
//             <i className="fa fa-instagram"></i>
//           </a>
//           <a href="https://www.facebook.com" target="_blank" rel="noopener noreferrer">
//             <i className="fa fa-facebook"></i>
//           </a>
//           <a href="https://wa.me/9467586586" target="_blank" rel="noopener noreferrer">
//             <i className="fa fa-whatsapp"></i>
//           </a>
//         </div>
//      </div>
//       </div>
//     </div>
//     </div>
//   );
// };

// export default Footer;


// Footer.js
// Footer.js
import React from 'react';
import { FaFacebook, FaWhatsapp, FaInstagram, FaLinkedin, FaPhoneAlt, FaMapMarkerAlt, FaEnvelope } from 'react-icons/fa';
import './Footer.css';

const Footer = () => {
  return (
    
    <footer className="footer">
      <div className="footer-top">
        {/* Logo Section */}
        <div className="footer-logo">
          <img src="https://i.ibb.co/dM00xCs/icon.png" alt="Logo" />
        </div>

        {/* Main Menu Section */}
        <div className="footer-menu">
          <div className="footer-main-menu">
            <h4>Main Menu</h4>
            <ul>
              <li><a href="/">Home</a></li>
              <li><a href="/about-us">About Us</a></li>
              <li><a href="/contact">Contact Us</a></li>
            </ul>
          </div>
          
          {/* Quick Links Section */}
          <div className="footer-quick-links">
            <h4>Quick Links</h4>
            <ul>
              <li><a href="/privacy-policy">Privacy Policy</a></li>
              <li><a href="/terms-of-use">Terms & Conditions</a></li>
              
            </ul>
          </div>
        </div>

        {/* Contact Info Section */}
        <div className="footer-contact">
          <h4>Contact Info</h4>
          <p><FaPhoneAlt /> +91 9467-586-586</p>
          <p><FaEnvelope /> inquiries.solutions@helptechi.com</p>
          <p><FaMapMarkerAlt /> Prime Solution Hub,<br></br>Shop No.1, Akshey Apartment Rambaug, Lane No.5, <br></br> Near Vani Vidaylya Kalyan, Thane(421301)</p>
        </div>
        
        {/* Social Media Links */}
        <div className="footer-social">
          <h4>Follow Us</h4>
          <div className="social-icons">
            <a href="https://facebook.com" target="_blank" rel="noopener noreferrer"><FaFacebook /></a>
            <a href="https://wa.me/9467586586" target="_blank" rel="noopener noreferrer"><FaWhatsapp /></a>
            <a href="https://instagram.com" target="_blank" rel="noopener noreferrer"><FaInstagram /></a>
            <a href="https://linkedin.com" target="_blank" rel="noopener noreferrer"><FaLinkedin /></a>
          </div>
        </div>
      </div>

      {/* Footer Bottom */}
      <div className="footer-bottom">
        <p>© 2025 HelpTechi. All rights reserved.</p>
      </div>
    </footer>
  );
};

export default Footer;
