import React from "react";
import styles from './Logo.module.css'

function Logo() {
    return (
        <a href="/">
            <img
                src="https://i.ibb.co/tcPyngw/logo.png"
                alt="HelpTechi Logo"
                className={styles.Logo}
            />
        </a>
    )
}

export default Logo;