// src/components/MyProfile/MyProfile.js
import React, { useEffect, useState } from 'react';
import EditProfile from './EditProfile';
import Subscription from './Subscription';
import UserList from '../UserList/UserList';
import EditPriceList from '../EditPriceList/EditPriceList';

function MyProfile() {
  const [activeTab, setActiveTab] = useState('editProfile');
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    
    const userId = JSON.parse(localStorage.getItem('userDetails'));
    
    const role = userId.role || null;

    if (role === 'admin') {
      setIsAdmin(true);
    }
  }, []);

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  return (
    <div>
      {/* <h1>My Profile</h1> */}
      <div className="nav nav-tabs">
        <button
          className={`nav-link ${activeTab === 'editProfile' ? 'active' : ''}`}
          onClick={() => handleTabChange('editProfile')}
        >
          My Profile
        </button>
        <button
          className={`nav-link ${activeTab === 'subscription' ? 'active' : ''}`}
          onClick={() => handleTabChange('subscription')}
        >
          My Subscriptions
        </button>
        {isAdmin && (
        //   <button className={`nav-link ${activeTab === 'userList' ? 'active' : ''}`} onClick={() => handleTabChange('userList')}>User List</button>
        //   <button className={`nav-link ${activeTab === 'editPriceList' ? 'active' : ''}`} onClick={() => handleTabChange('editPriceList')}>
        //   Edit Price List
        // </button>
        <>
        <button className={`nav-link ${activeTab === 'userList' ? 'active' : ''}`} onClick={() => handleTabChange('userList')}>
          User List
        </button>
        <button className={`nav-link ${activeTab === 'editPriceList' ? 'active' : ''}`} onClick={() => handleTabChange('editPriceList')}>
          Edit Price List
        </button>
      </>
        )}
      </div>

      <div className="tab-content">
        {activeTab === 'editProfile' && <EditProfile />}
        {activeTab === 'subscription' && <Subscription />}
        { isAdmin &&  activeTab === 'userList' && <UserList />}
        {isAdmin && activeTab === 'editPriceList' && <EditPriceList />}
      </div>
    </div>
  );
}

export default MyProfile;

