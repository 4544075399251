import React from 'react';
import UpperNavBar from './UpperNavBar';
import LowerNavBar from './LowerNavBar';
import Nav from './Nav'
import './NavBar.css';

const NavBar = () => {
  return (
    <>
     <div className="NavBar-container">
     <Nav />   
     <LowerNavBar/> 
     </div>

    </>
  );
};

export default NavBar;
